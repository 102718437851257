import React from "react"
import {navigate} from "gatsby"

import UserHelper from "../utils/helpers/user"

const ConsolePage = () => {
    return <ConsolePageComponent/>
}

class ConsolePageComponent extends React.Component {
    // constructor(props) {
    //     super(props);
    // } 

    async componentDidMount(){
        if(UserHelper.HasLoggedInUser()){
            navigate(`/console/dashboard`);
        }
        else{
            navigate(`/console/login`);
        }
    }

    render () {
        return <></>;
    }
}

export default ConsolePage;